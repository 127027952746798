@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f1f1f1;
}
.nav {
  background: black;
  height: 70px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.search-container input {
  height: 43px;
  width: 600px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  border: none;
  font-size: 16px;
  padding: 0 10px;
  box-sizing: border-box;
}
.search-container {
  position: relative;
}
.search-results {
  position: absolute;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  top: 42px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 100;
}
.search-result .movie-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.search-result .movie-info button {
  margin: 20px 10px;
  background: #21ba45;
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  border: none;
  padding: 9px 0;
  border-radius: 4px;
}
.search-result {
  display: flex;
}
.search-result img {
  height: 150px;
}
.search-result > div span {
  font-size: 20px;
  padding: 10px;
  font-weight: 600;
}

.search-container input:focus,
.search-container button:focus,
.footer button:focus,
.search-result .movie-info button:focus {
  outline: none;
}
.search-container button:hover,
.footer button:hover,
.search-result .movie-info button:hover {
  cursor: pointer;
}
#search-btn {
  background: #f2711c;
  height: 43px;
  color: #fff;
  border: none;
  font-size: 15px;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  top: -1px;
}
.main {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}
.main .tabs {
  display: flex;
  font-size: 20px;
}
.main .tabs .tab {
  background: #fff;
  padding: 10px;
  margin-right: 13px;
  border-radius: 3px;
  border: 1px solid #e1e1e1;
}
.active-tabs {
  background: #2185d0 !important;
  color: white;
}
.main .tabs .tab:hover {
  cursor: pointer;
  background: #e1e1e1;
}
.movie-card {
  background: #fff;
  padding: 0;
  border: none;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #f1f1f1;
  display: flex;
}
.movie-card:not(:first-child) {
  margin-top: 20px;
}
.movie-card:last-child {
  margin-bottom: 50px;
}
.movie-card .left {
  flex: 1;
}
.movie-card .left img {
  height: 250px;
}
.movie-card .right {
  position: relative;
  flex: 10;
}
.movie-card .title {
  padding: 10px 20px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 25px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.movie-card .plot, .Genre {
  margin-top: 20px;
  padding: 0 40px;
}
.Genre{
  display: flex;
  justify-content: space-around;
  width: 80%;
}
.footer {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}
.footer .rating {
  font-size: 20px;
  font-weight: 600;
}
.footer button {
  font-size: 15px;
  padding: 7px 10px;
  text-shadow: none;
  border-radius: 3px;
  border: none;
  font-weight: 600;
}
.favourite-btn {
  background-color: #2185d0;
  color: #fff;
}
.unfavourite-btn {
  background-color: #db2828;
  color: #fff;
  text-shadow: none;
}
.no-movies {
  padding: 20px 0;
  font-size: 20px;
  color: #6d6d6d;
}

.right img{
  width: 50px;
}

figure{
  margin: 0;
}


/* Box-shadow for better visual depth */
.shadow-lg {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

/* Button Hover Transition */
.transition {
  transition: all 0.3s ease-in-out;
}

.hover\:bg-purple-600:hover {
  background-color: #6d28d9;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}
